import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import {
    KnownBreakpoints,
    MAINTENANCE_CONSUMER_WEBSITE,
    THEME_BREAKPOINTS,
} from '../../common/constants';
import { ApiClient, isLight, useFooterStyles } from '../../common/helpers';
import { useConfig } from '../../common/hooks/ConfigProvider';
import SmartLink from '../../components/SmartLink';
import { FooterProps } from './Desktop';

const styles = {
    footer: (theme: Theme, withChatWithManager: boolean) => ({
        paddingBottom: '35px',
        [theme.breakpoints.between(
            THEME_BREAKPOINTS[KnownBreakpoints.tablet],
            THEME_BREAKPOINTS[KnownBreakpoints.laptop],
        )]: {
            width: withChatWithManager ? '80%' : '100%',
            marginTop: '20px',
        },
    }),
    footerInfo: (theme: Theme, noFooterItems?: boolean) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: noFooterItems ? 'flex-end' : 'space-between',
        paddingTop: '18px',
        [theme.breakpoints.between(
            THEME_BREAKPOINTS[KnownBreakpoints.tablet],
            THEME_BREAKPOINTS[KnownBreakpoints.laptop],
        )]: {
            display: noFooterItems ? 'flex' : 'block',
        },
    }),
    footerHalf: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    footerTextDivider: {
        display: 'flex',
        alignItems: 'center',
    },
    info: (theme: Theme, noFooterItems?: boolean, color?: string) => ({
        fontSize: theme.typography.fontSize,
        lineHeight: '22px',
        [theme.breakpoints.between(
            THEME_BREAKPOINTS[KnownBreakpoints.tablet],
            THEME_BREAKPOINTS[KnownBreakpoints.laptop],
        )]: {
            marginTop: noFooterItems ? 'unset' : '20px',
        },
        color: color ? color : isLight(theme)
            ? theme.palette.secondary.dark
            : theme.palette.info.light
        ,
    }),
    verticalDivider: (theme: Theme, color?: string) => ({
        fontSize: theme.typography.fontSize,
        color: color ? color : isLight(theme)
            ? theme.palette.secondary.dark
            : theme.palette.info.light
        ,
    }),
    horizontalDivider: (theme: Theme, color?: string) => ({
        border: `1px solid ${ color || theme.palette.divider }`,
    }),
};

const VerticalDivider: React.FunctionComponent<{ color?: string }> = props => {
    const theme = useTheme<Theme>();

    return <Typography sx={ styles.verticalDivider(theme, props?.color) }>
        &nbsp; | &nbsp;
    </Typography>
};

const DesktopFooter: FunctionComponent<FooterProps> = props => {
    const { consumerName  } = useConfig();
    const theme = useTheme<Theme>();
    const classes = useFooterStyles({ color: props?.color });
    const copyRight = props?.maintenanceCode === MAINTENANCE_CONSUMER_WEBSITE
        ? <text>{ consumerName }</text>
        : <SmartLink href="/" className={ classes.footerLink }>
            { consumerName }
        </SmartLink>;
    const noFooterItems = props?.maintenanceCode
        === MAINTENANCE_CONSUMER_WEBSITE;

    const [footerItems, setFooterItems] = useState<
        { name: string, path: string }[] | undefined
    >(undefined);

    useEffect(() => {
        (async () => {
            const { data } = await ApiClient.request('/footerMenu');
            const items = data.map((item: any) => ({
                name: item.title,
                path: '/' + item.link,
            }));

            setFooterItems(items);
        })();
        // eslint-disable-next-line
    }, []);

    return <Box sx={ styles.footer(theme, props.withChatWithManager) }>
        <Divider sx={ styles.horizontalDivider(theme, props?.color) }/>
        <Box sx={ styles.footerInfo(theme, noFooterItems) }>
            { footerItems
                && props?.maintenanceCode !== MAINTENANCE_CONSUMER_WEBSITE
                && <Box
                    sx={ styles.footerHalf }
                >
                    { footerItems.map((item, i) =>
                        <Fragment key={ i }>
                            <Box sx={ styles.footerTextDivider }>
                                { i !== 0 && <VerticalDivider
                                    color={ props?.color }
                                /> }<SmartLink
                                className={ classes.footerLink }
                                href={ item.path }
                            >{ item.name }</SmartLink>
                            </Box>
                        </Fragment>,
                    ) }
                </Box>
            }
            <Typography sx={ styles.info(theme, noFooterItems, props?.color) }>
                &copy; { copyRight }, { new Date().getFullYear() }
            </Typography>
        </Box>
    </Box>;
};

export default DesktopFooter;
