import React, { FunctionComponent } from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import { isLight } from '../common/helpers';
import { CONTENT_MARGIN_TOP } from '../config';

const styles = {
    paper: (theme: Theme): SxProps => ({
        marginTop: `-${ CONTENT_MARGIN_TOP }px`,
        padding: '0 40px',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main
        ,
    }),
};

const MainDesktopPaper: FunctionComponent<
    PaperProps & { mainDesktopPaperStyles?: SxProps }
> = ({ mainDesktopPaperStyles = {}, sx, children, ...otherProps }) => <Paper
    { ...otherProps }
    sx={ theme => ({
        ...styles.paper(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
        ...mainDesktopPaperStyles,
    })}
>
    { children }
</Paper>;

export default MainDesktopPaper;
