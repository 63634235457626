export function prettifyLoanAgreement() {
    const tdElements = document.querySelectorAll('td');

    if (tdElements.length > 0) {
        tdElements.forEach(td => {
            if (td.innerHTML.trim() === '') {
                td.remove();
            }
        });
    }

    const paymentScheduleTableTrs = document.querySelectorAll(
        'table.paymentSchedule tbody tr',
    );

    if (paymentScheduleTableTrs.length > 0) {
        paymentScheduleTableTrs.forEach(tr => {
            (tr as any).style.setProperty('margin-bottom', '0', 'important');
        });
    }

    const targetDiv = document.querySelector('div[style*="width: 55%;"]');

    if (targetDiv) {
        (targetDiv as any).style.width = '100%';
    }

    const amountFinancedTableTrs = document.querySelectorAll(
        'table.normalFont.noPad tbody tr',
    );

    if (amountFinancedTableTrs.length > 0) {
        amountFinancedTableTrs.forEach(tr => {
            (tr as any).style.setProperty(
                'border', '1px solid #fff', 'important',
            );
        });
    }
}
