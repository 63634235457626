import { Palette, Theme } from '@mui/material/styles';
import {
    AvailableThemes,
    KnownThemes,
} from '../themes';
import { makeStyles } from '@mui/styles';

export function isTheme(
    theme: Theme | Palette,
    which: keyof AvailableThemes,
): boolean {
    const mode = theme && (theme as Theme).palette
        ? (theme as Theme).palette.mode
        : (theme && (theme as Palette).mode)
            ? (theme as Palette).mode
            : KnownThemes.light
    ;

    return mode === which;
}

export function isDark(theme: Theme | Palette): boolean {
    return isTheme(theme, KnownThemes.dark);
}

export function isLight(theme: Theme | Palette): boolean {
    return isTheme(theme, KnownThemes.light);
}

export const useFooterStyles = makeStyles<
    Theme, { color?: string, fontSize?: string }
>((theme: Theme) => ({
    footerLink: (props: { color?: string, fontSize?: string }) => ({
        padding: 0,
        margin: 0,
        color: props.color || (isLight(theme)
            ? theme.palette.secondary.dark
            : theme.palette.info.light),
        textTransform: 'none',
        fontSize: props.fontSize || theme.typography.fontSize,
        minWidth: 'unset',
        lineHeight: '22px',
        cursor: 'pointer',
        textDecoration: 'unset',
        '&:hover': {
            color: props.color || (isLight(theme)
                ? theme.palette.secondary.dark
                : theme.palette.info.light),
            textDecoration: 'underline',
        },
    }),
}));

