import React from 'react';
import Box from '@mui/material/Box';
import DesktopFooter from './DesktopFooter';
import BaseLayout, { ContentLayoutProps } from '../BaseLayout';

export interface FooterProps {
    withChatWithManager: boolean;
    maintenanceCode?: string;
    color?: string;
}

const styles = {
    footer: {
        marginTop: 'auto',
    },
};

export const Footer: React.FunctionComponent<
    FooterProps
> = props => <Box sx={ styles.footer }>
    <DesktopFooter
        withChatWithManager={ props.withChatWithManager || false }
        maintenanceCode={ props?.maintenanceCode }
        color={ props?.color }

    />
</Box>;

const DesktopLayout: React.FunctionComponent<ContentLayoutProps> = props => {
    return <BaseLayout
        { ...props }
        footer={
            <Footer
                withChatWithManager={ props.withChatWithManager || false }
                maintenanceCode={ props?.maintenanceCode }
                color={ props?.footerColor }
            />
        }
    >
        { props.children }
    </BaseLayout>;
};

export default DesktopLayout;
