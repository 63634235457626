import React, { PropsWithChildren, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../common/helpers';
import DesktopHeader from '../components/Header/Desktop';
import { AuthUser } from '../common/OfflineStore';
// import ChatWithManager from '../components/ChatWithManager';
import { HEADER_BOTTOM_HEIGHT } from '../config';
import { MenuItem } from '../components/Menu/types';
import MobileHeader from '../components/Header/Mobile';
import { KnownBreakpoints } from '../common/constants';
import { PageProps } from '../common/types/CmsEntities';

const styles = {
    layout: (theme: Theme, background?: string) => ({
        position: 'relative',
        backgroundColor: background
            ? background
            : isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.dark
        ,
    }),
    page: {
        width: '100%',
    },
    headerSticky: (
        theme: Theme,
        disableSticky?: boolean,
        headerDesktopBackground?: string,
    ) => ({
        background: headerDesktopBackground
            ? headerDesktopBackground
            : theme.palette.primary.main
        ,
        position: disableSticky ? 'relative' : 'sticky',
        padding: 0,
    }),
    headerContent: (theme: Theme) => ({
        padding: '0 40px',
        maxWidth: '1440px',
        margin: '0 auto',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            padding: '0',
        },
    }),
    mainContent: (theme: Theme, headerHeight: number) => ({
        padding: '0 40px',
        maxWidth: '1440px',
        margin: '0 auto',
        minHeight: `calc(100vh - ${ headerHeight }px)`,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 100,
        position: 'relative',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            padding: '0 20px',
        },
    }),
    footer: {
        marginTop: 'auto',
    },
};

export type LayoutProps = PropsWithChildren & PageProps & {
    user?: AuthUser | null;
};

export type HeaderBottomSticky = {
    element: React.JSX.Element,
    zIndex: number,
    top?: number,
    disableSticky?: boolean,
};

export interface ContentLayoutProps extends LayoutProps {
    title: string;
    description?: string;
    keywords?: string;
    withActions: boolean;
    withoutThemeSwitcher?: boolean;
    menuBackground?: string;
    headerBackground?: string;
    pageBackground?: string;
    headerDesktopBackground?: string;
    subtitle?: string | React.JSX.Element;
    headerContent?: React.JSX.Element;
    icon?: React.JSX.Element;
    menuItems?: MenuItem[];
    footerMenuItems?: MenuItem[];
    showFooterDisclaimer?: boolean; //TODO: make when static site development will start
    headerBottomSticky?: HeaderBottomSticky;
    withChatWithManager?: boolean;
    footer?: React.JSX.Element;
    setMenuOpen?: (state: boolean) => void;
    closeAction?: () => void;
    onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    maintenanceCode?: string;
    hideHeaderBorder?: boolean;
    isFullHeight?: boolean;
    withoutMobileFooter?: boolean;
    contentPadding?: string;
    footerColor?: string;
}

const BaseLayout: React.FunctionComponent<ContentLayoutProps> = props => {
    const [headerHeight, setHeaderHeight] = useState(HEADER_BOTTOM_HEIGHT);
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setEnv] = useState<string | undefined>(undefined);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const env = sessionStorage.getItem('env');

        if (env) {
            setEnv(env);
        }
    }, []);

    useEffect(() => {
        const headerTop = document.getElementById('header-top');
        const headerBottom = document.getElementById('header-bottom');
        const headerTopHeight = headerTop
            ? headerTop.offsetHeight -2
            : HEADER_BOTTOM_HEIGHT;
        const headerBottomHeight = headerBottom ? headerBottom.offsetHeight : 0;

        setHeaderHeight(() => (headerTopHeight + headerBottomHeight));
    }, []);

    const top = props.headerBottomSticky?.top === 0 ? 0 : 72;

    useEffect(() => {
        if (menuOpen) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
                duration: 0,
            } as any);
        }
    }, [menuOpen]);

    return <Box id="layout-id" sx={ styles.layout(theme, props.pageBackground) }>
        <Box sx={ styles.page }>
            <Box id="header-top" data-id="base-layout" sx={{
                ...styles.headerSticky(
                    theme,
                    menuOpen,
                    props.headerDesktopBackground,
                ),
                zIndex: 1000,
                top: 0,
                paddingBottom: isMobile ? '0' : '30px',
                marginBottom: '-2px',
            }}>
                <Box sx={ styles.headerContent }>
                    { !isMobile
                        ? <DesktopHeader
                            menuItems={ props.menuItems || [] }
                            setMenuOpen={ setMenuOpen }
                            menuBackgroundColor={ props.menuBackground }
                            withoutThemeSwitcher={ props.withoutThemeSwitcher }
                            maintenanceCode={ props?.maintenanceCode }
                        />
                        : <MobileHeader
                            { ...props }
                            setMenuOpen={ setMenuOpen }
                            withoutThemeSwitcher={ props.withoutThemeSwitcher }
                            menuBackground={ props.menuBackground }
                        />
                    }
                </Box>
            </Box>
            { props.headerBottomSticky && <Box id="header-bottom" sx={{
                ...styles.headerSticky(
                    theme,
                    isMobile || props.headerBottomSticky.disableSticky,
                    props.headerDesktopBackground,
                ),
                top,
                zIndex: props.headerBottomSticky.zIndex,
                padding: isMobile ? '35px 0' : '15px 0',
            }}>
                <Box sx={ styles.headerContent }>
                    { props.headerBottomSticky.element }
                </Box>
            </Box> }
            <Box
                id="layout-main-content"
                sx={ styles.mainContent(theme, headerHeight) }
            >
                { props.children }
                { props.footer && <Box sx={ styles.footer }>
                    { props.footer }
                </Box> }
            </Box>
        </Box>
        {/*Temporarily removed at the request of Collections*/}
        {/*{ props.withChatWithManager && <ChatWithManager /> }*/}
    </Box>;
};

export default BaseLayout;
