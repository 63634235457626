import React, { FC } from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import { SectionProps } from '../../types/Section';
import { BlogPost as BlogPostType } from '../../types/Content';
import SectionTitle from '../../components/SectionTitle';
import MarkdownBlogPost from '../../components/MarkdownBlogPost';
import SeeMoreButton from '../../components/SeeMoreButton';
import LibraryIcon from '../../../../assets/SvgIcons/LibraryIcon';
import getImageAttributes from '../../../../common/helpers/getImageAttributes';

const styles = {
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        marginBottom: '30px',
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
    },
    imageTextContainer: {
        marginBottom: '50px',
    },
    imageText: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '32px',
    },
    paragraph: {
        marginBottom: '50px',
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    blockText: {
        lineHeight: '24px',
    },
};

const BlogPost: FC<SectionProps> = props => {
    const {
        data: { title, image, intro, post },
    } = props as { data: BlogPostType };
    const img = getImageAttributes(image, 'large');

    return <React.Fragment>
        { title && <SectionTitle title={ title } /> }

        { img.height && <Box sx={ styles.imageContainer }>
            <Image
                priority
                alt={ img.alternativeText || '' }
                src={ img.url || '' }
                width={ 1100 }
                height={ img.height || 406 }
                style={ styles.image }
            />
        </Box> }

        <Box sx={ styles.imageTextContainer }>
            <MarkdownBlogPost text={ intro } sx={ styles.imageText } />
        </Box>

        <Box sx={ styles.paragraph } >
            <MarkdownBlogPost text={ post } sx={ styles.blockText } />
        </Box>

        <Box>
            <SeeMoreButton
                startIcon={ <LibraryIcon /> }
                // Rollback: If the user opens a post not from a posts list
                // (eg. from another post) this button will not redirect to
                // the list of blog posts.
                // onClick={ () => window.history.back() }
                href={ '/blog' }
            >
                Back to all articles
            </SeeMoreButton>
        </Box>
    </React.Fragment>;
};

export default BlogPost;
