import React, { FunctionComponent } from 'react';
import Alert from '@mui/material/Alert';
import WarningIcon from '../assets/SvgIcons/WarningIcon';

const WarningFieldAlert: FunctionComponent<
    { message: string | JSX.Element }
> = ({ message }) => {
    return <Alert
        severity={ 'warning' }
        iconMapping={{ error: <WarningIcon/> }}
        sx={{ '& .MuiAlert-message': { marginRight: '12px' } }}
    >{ message }</Alert>;
};

export default WarningFieldAlert;
