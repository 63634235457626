import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '../../../assets/SvgIcons/ScheduleIcon';
import DocumentIcon from '../../../assets/SvgIcons/DocumentIcon';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../../../common/constants';

const styles = {
    documentContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    documentButton: (theme: Theme) => ({
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        lineHeight: '16.94px',
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        marginRight: '8px',
        whiteSpace: 'nowrap',
        '&:hover': { textDecoration: 'unset' },
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            '&:hover': { textDecoration: 'underline' },
        },
    }),
};

const defaultIcon = <DocumentIcon style={{ fontSize: '16px' }} />;
const scheduleIcon = <ScheduleIcon style={ { fontSize: '15px' } }/>;
const DocumentTypeMap = {
    paymentSchedule: scheduleIcon,
    loanDocuments: defaultIcon,
    paymentPlan: defaultIcon,
};

export type DocumentType = keyof typeof DocumentTypeMap;
export interface DocumentLinkProps {
    type: DocumentType;
    title: string;
    onClick?: () => void;
}

const DocumentLink: FunctionComponent<DocumentLinkProps> = props => {
    const icon = DocumentTypeMap[props.type] || defaultIcon;

    return <Box sx={ styles.documentContainer } onClick={ props.onClick }>
        <Typography sx={ styles.documentButton as any }>
            { props.title }
        </Typography>
        { icon }
    </Box>;
};

export default DocumentLink;
