export const CONSUMER_ID_HEADER = 'x-auth-consumer';

export const CONTENT_MARGIN_TOP = 132;
export const HEADER_BOTTOM_HEIGHT = 110;

export const HEADER_BUTTON_HOVER_COLOR = '#82c662';
export const MENU_ITEM_HOVER_COLOR = 'rgb(213, 236, 244, 85)';

export const BLOG_PAGINATION_LIMIT = 12;
export const RECENT_ARTICLES_LIMIT = 4;

export const MIN_PAYMENT_AMOUNT = 50;
