export function removeFirstESignaturePart() {
    const signatureItem = document.querySelector(
        'li:has(> small[style*="color: red;"])',
    );

    if (signatureItem) {
        const smallElement = signatureItem.querySelector(
            'small[style*="color: red;"]',
        );

        if (smallElement) {
            const elementsToRemove = [];

            let currentElement = smallElement;

            for (let i = 0; i < 5; i++) {
                if (currentElement) {
                    elementsToRemove.push(currentElement);

                    currentElement = currentElement
                        .nextElementSibling as Element;
                }
            }

            elementsToRemove.forEach(element => {
                if (element) {
                    element.remove();
                }
            });
        }
    }
}

export function removeSecondESignaturePart() {
    const signatureItem = document.querySelector(
        'p:has(> small[style*="color: red;"])',
    );

    if (signatureItem) {
        const elementsToRemove = [];

        let currentElement = signatureItem;

        for (let i = 0; i < 5; i++) {
            if (currentElement) {
                elementsToRemove.push(currentElement);

                currentElement = currentElement
                    .previousElementSibling as Element;
            }
        }

        elementsToRemove.forEach(element => {
            if (element) {
                element.remove();
            }
        });
    }
}

export function removeThirdESignaturePart() {
    const boldElements = document.querySelectorAll('b');

    if (boldElements.length !== 0) {
        const signatureElement = Array.from(boldElements).find(
            element => element.textContent?.includes('BY TYPING YOUR NAME AND '
                + 'CLICKING THE “I AGREE” BUTTON BELOW',
            ),
        );

        if (signatureElement) {
            signatureElement.remove();
        }
    }

    const targetDiv = document.querySelector(
        'div[style*="border: 2px solid rgb(160, 160, 160)"]'
        + ':has(> p[class="justify"])',
    );

    if (targetDiv) {
        targetDiv.remove();
    }
}
