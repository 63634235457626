import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import SingleBlockPage from '../../../layouts/SingleBlockPage';
import LoginForm from '../../LoginForm';
import { useConfig } from '../../../common/hooks/ConfigProvider';
import {
    NamedRoute,
    RoutePageProps,
} from '../../../common/hooks/useNamedRoute';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../../common/helpers';
import LogoIcon from '../../../assets/SvgIcons/LogoIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    KnownBreakpoints,
    LOGIN_LOGO,
    THEME_BREAKPOINTS,
} from '../../../common/constants';
import Paper from '@mui/material/Paper';
import CloseIcon from '../../../assets/SvgIcons/CloseIcon';
import SmartLink from '../../SmartLink';
import Typography from '@mui/material/Typography';
import ConsumerYearMobileInfo
    from '../../../layouts/PageLayout/ConsumerYearMobileInfo';

const styles = {
    desktopLayout: { display: 'flex' },
    logoContainer: (theme: Theme) => ({
        width: '59%',
        backgroundColor: isLight(theme)
            ? theme.palette.action.selected
            : theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    desktopContentContainer: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        padding: '100px 70px 100px 63px',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.dark,
    }),
    paper: {
        padding: 'unset',
        overflow: 'hidden',
    },
    logo: { width: '60%', height: '60%' },
    mobileLayout: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        overflow: 'hidden',
    },
    mobileTitle: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.action.selected
            : theme.palette.secondary.main,
        padding: '21px 0px 38px 0px',
        display: 'flex',
        flexDirection: 'column',
    }),
    mobileContent: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.dark,
        paddingBottom: '38px',
    }),
    iconContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        paddingRight: '16px',
    },
    icon: (theme: Theme) => ({
        color: isLight(theme)
            ? theme.palette.secondary.dark
            : theme.palette.common.white,
        fontSize: '13px',
    }),
    title: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '28.13px',
        padding: '33px 40px 0px 40px',
    },
    subtitle: (theme: Theme) => ({
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        padding: '13px 40px 0px 40px',
        '& a': {
            color: theme.palette.text.primary,
            textDecoration: 'underline',
            '&:hover': {
                color: theme.palette.text.primary,
                textDecoration: 'underline',
            },
        },
    }),
    copyright: { padding: '0 0 33px 20px' },
};

const DesktopContent: FunctionComponent<
    { showLogo: boolean }
> = ({ showLogo }) => {
    return <Box sx={ styles.desktopLayout }>
        <Box sx={ styles.logoContainer }>
            { showLogo && <LogoIcon sx={ styles.logo }/> }
        </Box>
        <Box sx={ styles.desktopContentContainer }>
            <LoginForm isForIntegration={ false }/>
        </Box>
    </Box>;
};

const MobileContent: FunctionComponent = () => {
    const { consumerName } = useConfig();

    return <Paper sx={ styles.mobileLayout }>
        <Box sx={ styles.mobileTitle }>
            <Box sx={ styles.iconContainer }>
                <SmartLink href={ '/' }>
                    <CloseIcon sx={ styles.icon }/>
                </SmartLink>
            </Box>
            <Typography component="h4" sx={ styles.title }>
                { 'Sign In to ' + consumerName }
            </Typography>
            <Typography sx={ styles.subtitle }>
                Welcome to member&apos;s area. Please, sign
                into your existing account or <SmartLink
                href={ NamedRoute.CREATE_APPLICATION }
                >register</SmartLink> a new one.
            </Typography>
        </Box>
        <Box sx={ styles.mobileContent }>
            <LoginForm isForIntegration={ false }/>
        </Box>
    </Paper>;
};

const MobileFooter: FunctionComponent = () => {
    const theme = useTheme();

    return <Box sx={ styles.copyright }>
        <ConsumerYearMobileInfo
            color={ theme.palette.common.white }
            fontSize="12px"
        />
    </Box>;
};

const LoginPage: React.FunctionComponent<RoutePageProps> = () => {
    const { consumerName } = useConfig();
    const theme = useTheme();
    const backgroundColor = isLight(theme)
        ? theme.palette.primary.main
        : theme.palette.primary.dark;
    const showLogo = useMediaQuery(theme.breakpoints.up(LOGIN_LOGO));
    const isMobile = useMediaQuery(
        theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );

    return <SingleBlockPage
        title={ `${ consumerName }` }
        withActions={ false }
        pageBackground={ backgroundColor }
        headerDesktopBackground={ backgroundColor }
        headerBackground={ backgroundColor }
        footerColor={ theme.palette.common.white }
        mainDesktopPaperStyles={ styles.paper }
        footer={ <MobileFooter/> }
    >
        { !isMobile
            ? <DesktopContent showLogo={ showLogo }/>
            : <MobileContent/>
        }
    </SingleBlockPage>;
};

export default LoginPage;
