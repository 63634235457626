import { paletteDark } from './palettes';

// TODO: fix hardcoded consumerId value
export const CURRENT_CONSUMER_ID = '1';
export const CACHE_KEY_PREFIX = 'creditcube';

export enum KnownBreakpoints {
    desktop = 'desktop',
    laptop = 'laptop',
    tablet = 'tablet',
    mobile = 'mobile',
}

// TODO: get PWA_COLOR from CMS pwaResources!
export const PWA_COLOR = paletteDark.palette.primary.main;

export const THEME_BREAKPOINTS: {
    [KnownBreakpoints.desktop]: number;
    [KnownBreakpoints.laptop]: number;
    [KnownBreakpoints.tablet]: number;
    [KnownBreakpoints.mobile]: number;
} = {
    [KnownBreakpoints.desktop]: 1441,
    [KnownBreakpoints.laptop]: 1281,
    [KnownBreakpoints.tablet]: 801,
    [KnownBreakpoints.mobile]: 0,
};

export const SUB_LAPTOP = 1101;
export const SMALL_PHONE = 320;
export const SMALL = 401;
export const TABLE_BREAKPOINT = 391;
export const EXTRA_SMALL = 351;
export const COMPLETE_BUTTON_BREAKPOINT = 310;
export const LOGIN_LOGO = 1000;

export const RX_PDF_MIME = /application\/pdf/i;

export const MAINTENANCE_CONSUMER_WEBSITE = 'MAINTENANCE_CONSUMER_WEBSITE';
export const MAINTENANCE_LMS = 'MAINTENANCE_LMS';

export const WEBSITE_MAINTENANCE_CODES = [MAINTENANCE_CONSUMER_WEBSITE];

export const MA_MAINTENANCE_CODES = [MAINTENANCE_LMS];

export const DEFAULT_MAINTENANCE_DESCRIPTION = 'Unfortunately Website is down '
    + 'for a bit of maintenance right now. But soon we will be up again.';
