import React, { FunctionComponent } from 'react';
import { ContentLayoutProps } from '../BaseLayout';
import Box from '@mui/material/Box';
import DialogLayoutMobileHeader from './DialogLayoutMobileHeader';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../common/helpers';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useRepaint } from '../../common/hooks/useRepaint';

const styles = {
    mobileLayout: (theme: Theme, pageColor?: string) => ({
        minHeight: '100vh',
        overflow: 'auto',
        backgroundColor: pageColor ? pageColor : isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.dark
        ,
        display: 'flex',
        flexDirection: 'column',
    }),
    mobileLayoutContainer: (
        isFullHeight?: boolean,
        contentPadding?: string,
    ) => ({
        padding: contentPadding ? contentPadding : '0 20px 20px',
        height: isFullHeight ? '100%' : 'auto',
    }),
    footer: {
        marginTop: 'auto',
    },
};

export interface DialogMobileProps {
    dialogProps: DialogProps;
    contentProps: ContentLayoutProps;
}

export const DialogMobileLayout: FunctionComponent<
    DialogMobileProps
> = props => {
    const { contentProps, dialogProps } = props;

    return <Dialog
        { ...dialogProps }
        fullScreen={ true }
        fullWidth={ true }
    >
        <MobileLayout { ...contentProps } />
    </Dialog>;
};

const MobileLayout: React.FunctionComponent<ContentLayoutProps> = props => {
    useRepaint();
    const theme = useTheme();

    return <Box
        sx={ styles.mobileLayout(theme, props?.pageBackground) }
        onScroll={ props.onScroll }
    >
        <DialogLayoutMobileHeader { ...props } />
        <Box sx={ styles.mobileLayoutContainer(
            props?.isFullHeight,
            props?.contentPadding,
        ) } >
            { props.children }
        </Box>
        { props.footer && <Box sx={ styles.footer } >
            { props.footer }
        </Box> }
    </Box>;
};

export default MobileLayout;
