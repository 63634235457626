import { FC } from 'react';
import Head from 'next/head';
import CmsClient from '../common/clients/CmsClient';
import StaticPage from '../components/StaticPage';
import { StaticPageProps } from '../components/StaticPage/types';
import parseEntity from '../common/helpers/parseEntity';
import getConfigWithRequest from '../common/helpers/getConfigWithRequest';
import { cache, generateKey } from '../common/StaticCache';
import { getFooterMenu, prepareFooter } from '../common/helpers/getFooterMenu';
import { PageProps, RESOURCE } from '../common/types/CmsEntities';

export const getServerSideProps = async (
    { req }: any,
): Promise<{ props: PageProps }> => {
    const slug = 'home';
    const { cmsApiUrl } = await getConfigWithRequest(req);
    const cacheKey = generateKey('page', slug);
    const cachedData = await cache.get(cacheKey);

    if (cachedData) {
        return cachedData;
    }

    const [
        { data: menu },
        { data: page },
        { data: sidebar },
        { data: footer },
        { data: footerMenu },
    ] = await Promise.all([
        CmsClient.getEntities(req, RESOURCE.menu),
        CmsClient.getEntityBySlug(req, RESOURCE.page, slug),
        CmsClient.getEntities(req, RESOURCE.sidebar),
        CmsClient.getEntities(req, RESOURCE.footer),
        getFooterMenu({ cmsApiUrl }),
    ]);

    const parsedMenu = parseEntity(menu);
    const parsedFooter = parseEntity(footer);

    const result = {
        props: {
            menu: parsedMenu,
            page: parseEntity(page),
            sidebar: parseEntity(sidebar),
            footer: prepareFooter({
                footer: parsedFooter,
                menu: footerMenu,
            }),
        },
    };

    await cache.set(cacheKey, result);

    return result;
};

const HomePage: FC<StaticPageProps> = props => {
    // TODO: refactor cms pages with metadata block
    const { name:title, description, keywords } = props.page;

    return <>
    {/*
        title, meta or any other elements (e.g. script) need to be contained as
        direct children of the Head element, or wrapped into maximum one level
        of <React.Fragment> or arrays—otherwise the tags won't be correctly
        picked up on client-side navigations.
        https://nextjs.org/docs/pages/api-reference/components/head#use-minimal-nesting
    */}
    <Head>
        <title>{ title }</title>
        { description && <meta name="description" content={ description } /> }
        { keywords && <meta name="keywords" content={ keywords } /> }
    </Head>
    <StaticPage { ...props } />
</>; };

export default HomePage;
