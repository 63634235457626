import { ApiClient } from './clients/ApiClient';
import { throttle } from './helpers/throttle';

export enum GtmStep {
    NONE = '',
    CREATE_APPLICATION = 'CREATE_APPLICATION',
    MEMBERS_AREA = 'MEMBERS_AREA',
    APPLY_1 = 'APPLY_1',
    APPLY_1_EDIT_BANK_INFO = 'APPLY_1_EDIT_BANK_INFO',
    APPLY_2_IBV = 'APPLY_2_IBV',
    APPLY_2_IBV_LOAN_AA_QUALIFIED = 'APPLY_2_IBV_LOAN_AA_QUALIFIED',
    APPLY_2_IBV_IN_PROGRESS = 'APPLY_2_IBV_IN_PROGRESS',
    APPLY_3_ESIG = 'APPLY_3_ESIG',
    APPLY_3_ESIG_LOAN_AA_QUALIFIED = 'APPLY_3_ESIG_LOAN_AA_QUALIFIED',
    APPLY_3_ESIG_SCHEDULE_LOADING = 'APPLY_3_ESIG_SCHEDULE_LOADING',
    APPLY_IN_PROGRESS = 'APPLY_IN_PROGRESS',
}

export enum GtmEvent {
    CREATE_APPLICATION_STEP_1_LANDED = 'CREATE_APPLICATION_STEP_1_LANDED',
    CREATE_APPLICATION_STEP_2_LANDED = 'CREATE_APPLICATION_STEP_2_LANDED',
    CREATE_APPLICATION_STEP_3_LANDED = 'CREATE_APPLICATION_STEP_3_LANDED',
    CREATE_APPLICATION_STEP_4_LANDED = 'CREATE_APPLICATION_STEP_4_LANDED',
    CREATE_APPLICATION_COMPLETE = 'CREATE_APPLICATION_COMPLETE',
    CREATE_APPLICATION_ERROR = 'CREATE_APPLICATION_ERROR',
    CREATE_APPLICATION_ACCEPTED = 'CREATE_APPLICATION_ACCEPTED',
    CREATE_APPLICATION_REJECTED = 'CREATE_APPLICATION_REJECTED',
    SELL_LEAD_LANDED = 'SELL_LEAD_LANDED',
    SELL_LEAD_ACCEPTED = 'SELL_LEAD_ACCEPTED',
    SELL_LEAD_REJECTED = 'SELL_LEAD_REJECTED',
    SELL_LEAD_ERROR = 'SELL_LEAD_ERROR',
    SELL_LEAD_HTTP_ERROR = 'SELL_LEAD_HTTP_ERROR',
    MEMBERS_AREA_LANDED = 'MEMBERS_AREA_LANDED',
    APPLY_1_LANDED = 'APPLY_1_LANDED',
    APPLY_1_AMOUNT_SELECTED = 'APPLY_1_AMOUNT_SELECTED',
    APPLY_1_EDIT_BANK_INFO_LANDED = 'APPLY_1_EDIT_BANK_INFO_LANDED',
    APPLY_1_EDIT_BANK_INFO_EMPLOYER_CHANGED = 'APPLY_1_EDIT_BANK_INFO_EMPLOYER_CHANGED',
    APPLY_1_EDIT_BANK_INFO_MONTHLY_INCOME_CHANGED = 'APPLY_1_EDIT_BANK_INFO_MONTHLY_INCOME_CHANGED',
    APPLY_1_EDIT_BANK_INFO_PAY_FREQUENCY_CHANGED = 'APPLY_1_EDIT_BANK_INFO_PAY_FREQUENCY_CHANGED',
    APPLY_1_EDIT_BANK_INFO_PAY_DATES_CHANGED = 'APPLY_1_EDIT_BANK_INFO_PAY_DATES_CHANGED',
    APPLY_1_EDIT_BANK_INFO_BANK_NAME_CHANGED = 'APPLY_1_EDIT_BANK_INFO_BANK_NAME_CHANGED',
    APPLY_1_EDIT_BANK_INFO_ROUTING_NUMBER_CHANGED = 'APPLY_1_EDIT_BANK_INFO_ROUTING_NUMBER_CHANGED',
    APPLY_1_EDIT_BANK_INFO_ACCOUNT_NUMBER_CHANGED = 'APPLY_1_EDIT_BANK_INFO_ACCOUNT_NUMBER_CHANGED',
    APPLY_1_EDIT_BANK_INFO_SAVED = 'APPLY_1_EDIT_BANK_INFO_SAVED',
    APPLY_1_EDIT_BANK_INFO_SAVE_ERROR = 'APPLY_1_EDIT_BANK_INFO_SAVE_ERROR',
    APPLY_1_EDIT_BANK_INFO_CANCELLED = 'APPLY_1_EDIT_BANK_INFO_CANCELLED',
    APPLY_1_CONTINUE = 'APPLY_1_CONTINUE',
    APPLY_1_CANCELLED = 'APPLY_1_CANCELLED',
    APPLY_2_IBV_LANDED = 'APPLY_2_IBV_LANDED',
    APPLY_2_IBV_LOAN_AA_QUALIFIED = 'APPLY_2_IBV_LOAN_AA_QUALIFIED',
    APPLY_2_IBV_LOAN_AA_UNQUALIFIED = 'APPLY_2_IBV_LOAN_AA_UNQUALIFIED',
    APPLY_2_IBV_FRAME_LOADED = 'APPLY_2_IBV_FRAME_LOADED',
    APPLY_2_IBV_IN_PROGRESS = 'APPLY_2_IBV_IN_PROGRESS',
    APPLY_2_IBV_DONE = 'APPLY_2_IBV_DONE',
    APPLY_2_IBV_VENDOR_DONE = 'APPLY_2_IBV_VENDOR_DONE',
    APPLY_2_IBV_ERROR = 'APPLY_2_IBV_ERROR',
    APPLY_2_IBV_VENDOR_ERROR = 'APPLY_2_IBV_VENDOR_ERROR',
    APPLY_2_IBV_CANCELLED = 'APPLY_2_IBV_CANCELLED',
    APPLY_3_ESIG_LANDED = 'APPLY_3_ESIG_LANDED',
    APPLY_3_ESIG_LOAN_AA_QUALIFIED = 'APPLY_3_ESIG_LOAN_AA_QUALIFIED',
    APPLY_3_ESIG_LOAN_AA_UNQUALIFIED = 'APPLY_3_ESIG_LOAN_AA_UNQUALIFIED',
    APPLY_3_ESIG_LOAN_AMOUNT_CHANGED = 'APPLY_3_ESIG_LOAN_AMOUNT_CHANGED',
    APPLY_3_ESIG_LOAN_AMOUNT_CHANGED_REFINANCE = 'APPLY_3_ESIG_LOAN_AMOUNT_CHANGED_REFINANCE',
    APPLY_3_ESIG_LOAN_AMOUNT_CHANGE_ERROR = 'APPLY_3_ESIG_LOAN_AMOUNT_CHANGE_ERROR',
    APPLY_3_ESIG_LOAN_AMOUNT_CHANGE_REFINANCE_ERROR = 'APPLY_3_ESIG_LOAN_AMOUNT_CHANGE_REFINANCE_ERROR',
    APPLY_3_ESIG_SCHEDULE_LOADING = 'APPLY_3_ESIG_SCHEDULE_LOADING',
    APPLY_3_ESIG_SCHEDULE_LOADED = 'APPLY_3_ESIG_SCHEDULE_LOADED',
    APPLY_3_ESIG_SCHEDULE_EXPANDED = 'APPLY_3_ESIG_SCHEDULE_EXPANDED',
    APPLY_3_ESIG_SCHEDULE_COLLAPSED = 'APPLY_3_ESIG_SCHEDULE_COLLAPSED',
    APPLY_3_ESIG_LOAN_AGREEMENT_CHECKED = 'APPLY_3_ESIG_LOAN_AGREEMENT_CHECKED',
    APPLY_3_ESIG_LOAN_AGREEMENT_UNCHECKED = 'APPLY_3_ESIG_LOAN_AGREEMENT_UNCHECKED',
    APPLY_3_ESIG_CONSENT_EC_CHECKED = 'APPLY_3_ESIG_CONSENT_EC_CHECKED',
    APPLY_3_ESIG_CONSENT_EC_UNCHECKED = 'APPLY_3_ESIG_CONSENT_EC_UNCHECKED',
    APPLY_3_ESIG_ACH_AUTH_CHECKED = 'APPLY_3_ESIG_ACH_AUTH_CHECKED',
    APPLY_3_ESIG_ACH_AUTH_UNCHECKED = 'APPLY_3_ESIG_ACH_AUTH_UNCHECKED',
    APPLY_3_ESIG_PRIVACY_POLICY_CHECKED = 'APPLY_3_ESIG_PRIVACY_POLICY_CHECKED',
    APPLY_3_ESIG_PRIVACY_POLICY_UNCHECKED = 'APPLY_3_ESIG_PRIVACY_POLICY_UNCHECKED',
    APPLY_3_ESIG_NAME_SIGNED = 'APPLY_3_ESIG_NAME_SIGNED',
    APPLY_3_ESIG_NAME_SIGNED_ERROR = 'APPLY_3_ESIG_NAME_SIGNED_ERROR',
    APPLY_3_ESIG_CONFIRMED = 'APPLY_3_ESIG_CONFIRMED',
    APPLY_3_ESIG_CONFIRM_ERROR = 'APPLY_3_ESIG_CONFIRM_ERROR',
    APPLY_3_ESIG_START_ERROR = 'APPLY_3_ESIG_START_ERROR',
    APPLY_3_ESIG_COMPLETE_ERROR = 'APPLY_3_ESIG_COMPLETE_ERROR',
    APPLY_3_ESIG_CANCELLED = 'APPLY_3_ESIG_CANCELLED',
    APPLY_IN_PROGRESS = 'APPLY_IN_PROGRESS',
    APPLY_SENT_FOR_REVIEW = 'APPLY_SENT_FOR_REVIEW',
    APPLY_DENIED = 'APPLY_DENIED',
    APPLY_APPROVED = 'APPLY_APPROVED',
    APPLY_ERROR = 'APPLY_ERROR',
    DMCC_CALL_CLICKED = 'DMCC_CALL_CLICKED',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    SELECT_PAYMENT_PLAN_LANDED = 'SELECT_PAYMENT_PLAN_LANDED',
    SELECT_PAYMENT_PLAN_CANCELED = 'SELECT_PAYMENT_PLAN_CANCELED',
    PAYMENT_PLAN_SELECTED = 'PAYMENT_PLAN_SELECTED',
    SELECT_PAYMENT_DATE_LANDED = 'SELECT_PAYMENT_DATE_LANDED',
    SELECT_PAYMENT_DATE_CANCELED = 'SELECT_PAYMENT_DATE_CANCELED',
    PAYMENT_DATE_SELECT = 'PAYMENT_DATE_SELECT',
    NEW_PAYMENT_PLAN_LANDED = 'NEW_PAYMENT_PLAN_LANDED',
    NEW_PAYMENT_PLAN_CANCELED = 'NEW_PAYMENT_PLAN_CANCELED',
    NEW_PAYMENT_PLAN_CONFIRMED = 'NEW_PAYMENT_PLAN_CONFIRMED',
}

function scope(): {
    dataLayer: any[];
    __app_step: GtmStep;
    __debug_gtm_events: boolean;
    __gtm_tracker_initialized: boolean;
} {
    const emptyScope = {
        dataLayer: [],
        __app_step: GtmStep.NONE,
        __debug_gtm_events: false,
        __gtm_tracker_initialized: false,
    };

    if (typeof window === 'undefined') {
        return emptyScope;
    }

    return (window as any);
}

export function resetGtmStep(): void {
    scope().__app_step = GtmStep.NONE;
}

export function hasGtmStep(): boolean {
   return !!scope().__app_step;
}

export function setGtmStep(eventStep: GtmStep): void {
    scope().__app_step = eventStep;
}

// This function to be used only with input elements on the forms
export const fireKeyboardInputChange = throttle(fireGtmEvent, 500);

export function fireGtmEvent(
    eventName?: GtmEvent | string,
    eventParams?: any,
): void {
    if (!eventName) {
        return ;
    }

    scope().dataLayer?.push({ event: eventName, params: eventParams });

    if (typeof window !== 'undefined') {
        ApiClient.request('/trackEvent', { body: {
            clientDate: new Date(),
            name: eventName,
            params: eventParams,
        } }).catch();
    }
}

export function getGtmStepEvent(): string | undefined {
    return scope().__app_step + '_WINDOW_CLOSED' as GtmEvent;
}

// uncomment the code below for debugging purpose,
// avoid publishing this to production
/*function trackEvents() {
    const original = scope().dataLayer?.push;

    if (!original) {
        return;
    }

    scope().dataLayer.push =  (...args: any[]): any => {
        const res = original.apply(scope().dataLayer, args);

        scope().__debug_gtm_events && console.log(...args);

        return res;
    };
}

function debug(enable: boolean): void {
    scope().__debug_gtm_events = enable;
}

if (typeof window !== 'undefined') {
    (window as any).gtmDebug = debug;

    if (!scope().__gtm_tracker_initialized) {
        scope().__gtm_tracker_initialized = true;
        (window as any).addEventListener('load', () => trackEvents());
    }
}*/
