import React, { FunctionComponent, useEffect, useState } from 'react';
import { EditInfoFormProps, styles } from './PersonalInfoForm';
import Box from '@mui/material/Box';
import { DesktopDialogTitle } from '../../../DialogPaperContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
    ACCOUNT_TYPES_MAP,
    PROFILE_INFO_UPDATE_SUBJECT,
} from '../../../../config/membersArea';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../../../common/constants';
import {
    formatNullableScalar,
    formatUpdatedFieldsToEmailText,
} from '../../../../common/helpers/format';
import { ApiClient, RestMethod, isOffline } from '../../../../common/helpers';
import useAuthorized from '../../../../common/hooks/useAuthorized';
import { Loader } from '../../PageLoader';
import { Alert } from '@mui/material';
import WarningIcon from '../../../../assets/SvgIcons/WarningIcon';
import MaskedTextField from '../../../MaskedTextField';
import asyncLogger from '../../../../common/logger';
import OfflineError from '../../../OfflineError';
import { useAccountInfo } from '../../../../common/hooks/AccountInfoProvider';
import { useConfig } from '../../../../common/hooks/ConfigProvider';
import WarningFieldAlert from '../../../WarningFieldAlert';

export interface BankInfoState {
    abaNumber: string;
    accountNumber: string;
    bankName: string;
    accountType: string;
}

const BankInfoForm: FunctionComponent<EditInfoFormProps> = props => {
    const authorized = useAuthorized();
    const { consumerOperationsDetails: { phoneNumber }} = useConfig() as any;
    const {
        content,
        onClose,
        onSuccess,
        onError,
        openRequestAlert,
    } = props;
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );
    const { customer } = useAccountInfo();
    const loanId = customer?.currentLoan?.id;
    const [state, setState] = useState<BankInfoState>({
        bankName: formatNullableScalar(content['Bank name'] as string),
        abaNumber: formatNullableScalar(content['Routing number'] as string),
        accountNumber: formatNullableScalar(
            content['Account number'] as string,
        ),
        accountType: formatNullableScalar(content['Account type'] as string),
    });
    const [
        emptyFieldError,
        setEmptyFieldError,
    ] = useState<string | undefined>(undefined);
    const [
        abaValidateWarning,
        setAbaValidateWarning,
    ] = useState<JSX.Element | undefined>(undefined);
    const [
        abaNumberError,
        setAbaNumberError,
    ] = useState<string | undefined>(undefined);
    const [
        submitError,
        setSubmitError,
    ] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [networkError, setNetworkError] = useState(false);

    const abaInvalid = 'Invalid Routing Number';
    const abaWarning = <p>
        { 'Important Notification! The bank account you entered is' }
        <strong>{ ' not accepted ' }</strong>
        { 'by our system. Please use' }
        <strong>{ ' another bank account' }</strong>.
        { ' If you need assistance, contact our customer support team at ' }
        { phoneNumber }.
    </p>;
    const abaError = 'Error. Your bank may be not accepted, or some of your '
        + 'provided data is incorrect!';

    useEffect(() => {
        if (state.abaNumber !== ''
            && !state.abaNumber.includes('_')
            && isChanged
        )
            (async (): Promise<void> => {
                const { data } = await ApiClient.request(
                    '/routingNumberInfo', {
                        method: RestMethod.GET,
                        withAuth: false,
                        query: { routingNumber: state.abaNumber },
                    },
                );

                const bankName = data?.routingNumber?.name;
                const acceptable = data?.routingNumber?.acceptable;

                if (bankName) {
                    setState({
                        ...state,
                        bankName: bankName,
                    });
                    setAbaNumberError(undefined);

                    if (!acceptable) {
                        setAbaValidateWarning(abaWarning);
                    }
                } else {
                    setAbaNumberError(() => abaInvalid);
                }
            })().catch(asyncLogger.error);
        // eslint-disable-next-line
    }, [state.abaNumber]);

    const handleChange = (prop: string) => (
        event: React.ChangeEvent<
            HTMLElement & { value: any }
            > | SelectChangeEvent,
    ) => {
        setEmptyFieldError(undefined);
        setSubmitError(undefined);

        if (prop === 'abaNumber') {
            setIsChanged(true);
            setAbaNumberError(undefined);
            setAbaValidateWarning(undefined);
        }

        setState({ ...state, [prop]: event.target.value });
    };

    const handleSaveChanges = async(): Promise<void> => {
        if (!authorized) {
            return ;
        }

        if (abaNumberError) {
            return ;
        }

        if (state.bankName === ''
            || state.abaNumber === ''
            || state.accountNumber === ''
            || state.accountType === ''
        ) {
            setEmptyFieldError('Required!');

            return ;
        }

        if (state.abaNumber.includes('_')) {
            setAbaNumberError(() => abaInvalid);

            return;
        }

        if (abaValidateWarning) {
            setAbaValidateWarning(undefined);
            setAbaNumberError(() => abaError);

            return;
        }

        if (!customer || !customer.id) {
            setSubmitError(
                'Updating Attempt Error. Unable to detect customer! '
                + 'Please reload the page...',
            );

            return ;
        }

        const oldInformation = {
            bankName: formatNullableScalar(content['Bank name'] as string),
            abaNumber: formatNullableScalar(
                content['Routing number'] as string,
            ),
            accountNumber: formatNullableScalar(
                content['Account number'] as string,
            ),
            accountType: formatNullableScalar(
                content['Account type'] as string,
            ),
        };

        if (state.bankName === oldInformation.bankName
            && state.abaNumber === oldInformation.abaNumber
            && state.accountNumber === oldInformation.accountNumber
            && state.accountType === oldInformation.accountType
        ) {
            onSuccess?.();
            onClose?.();

            return ;
        }

        const bankInfoForUpdate: { [key: string]: any }[] = [];

        if (state.bankName !== oldInformation.bankName) {
            bankInfoForUpdate.push({'Bank name' : state.bankName});
        }

        if (state.abaNumber !== oldInformation.abaNumber) {
            bankInfoForUpdate.push({'Routing number' : state.abaNumber});
        }

        if (state.accountNumber !== oldInformation.accountNumber) {
            bankInfoForUpdate.push(
                {'Account number' : state.accountNumber},
            );
        }

        if (state.accountType !== oldInformation.accountType) {
            const accType = state.accountType.charAt(0).toUpperCase() +
                state.accountType.toLowerCase().slice(1)
            ;

            bankInfoForUpdate.push({'Account type' : accType});
        }

        const emailText = formatUpdatedFieldsToEmailText(
            customer.id,
            bankInfoForUpdate,
        );

        setLoading(true);

        try {
            const { data } = await ApiClient.request<{
                data: { sendCustomerEmail: boolean, }
            }>('/contactUs', {
                body: {
                    formState: {
                        subject: { value: PROFILE_INFO_UPDATE_SUBJECT },
                        message: { value: emailText },
                        loanId: { value: loanId },
                    },
                },
                withAuth: true,
            });

            if (data?.sendCustomerEmail === true) {
                onSuccess?.(data);
                onClose?.();
                openRequestAlert?.();
            } else {
                setSubmitError(
                    'Updating Error. Please make sure that all data is correct!',
                );
            }
        } catch (err) {
            const isNetError = isOffline(err);
            setNetworkError(isNetError);

            if (!isNetError) {
                setSubmitError(
                    'Updating Error. Please make sure that all data is correct!',
                );

                asyncLogger.error(err);

                onError?.(err);
            }
        }

        setLoading(false);

        return ;
    };

    if (loading) {
        return <Loader  />;
    }

    return <Box sx={ styles.layout }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box>
            { isDesktop && <DesktopDialogTitle
                title={ 'Edit Bank Account Information' }
            /> }
            <Box marginTop={ isDesktop ? '35px' : '45px' }>
                { (emptyFieldError || abaNumberError || submitError) && <Alert
                    severity={ 'error' }
                    iconMapping={{
                        error: <WarningIcon/>,
                    }}
                >
                    { emptyFieldError
                        ? emptyFieldError
                        : abaNumberError
                            ? abaNumberError
                            : submitError
                    }
                </Alert> }
                { abaValidateWarning &&
                    <WarningFieldAlert message={ abaValidateWarning }/>
                }
                <Grid container spacing={ 1 }>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <TextField
                            label="Bank name*"
                            value={ state.bankName }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('bankName') }
                            inputProps={{ maxLength: 255 }}
                            error={
                                state.bankName === '' && !!emptyFieldError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <MaskedTextField
                            mask="999999999"
                            label="Routing number*"
                            value={ state.abaNumber }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('abaNumber') }
                            error={ (state.abaNumber === ''
                                && !!emptyFieldError) || !!abaNumberError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <TextField
                            type="number"
                            label="Account number*"
                            value={ state.accountNumber }
                            sx={{ width: '100%' }}
                            onChange={ handleChange('accountNumber') }
                            error={
                                state.accountNumber === '' && !!emptyFieldError
                            }
                        />
                    </Grid>
                    <Grid item mobile={ 12 } tablet={ 6 }>
                        <FormControl fullWidth>
                            <InputLabel>
                                Account type*
                            </InputLabel>
                            <Select
                                value={ state.accountType }
                                label="Account type*"
                                onChange={ handleChange('accountType') }
                                sx={ styles.select }
                            >
                                { Object.keys(ACCOUNT_TYPES_MAP).map((
                                    type: string,
                                    index: number,
                                ) => (
                                    <MenuItem
                                        value={ type }
                                        key={ index }
                                    >
                                        { type }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box sx={ styles.buttonsContainer }>
            { isDesktop
                ? <React.Fragment>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-save"
                    >
                        Save Changes
                    </Button>
                </React.Fragment>
                : <React.Fragment>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={ styles.saveButton }
                        onClick={ handleSaveChanges }
                        data-testid="btn-save"
                    >
                        Save Changes
                    </Button>
                    <Button
                        variant="outlined"
                        sx={ styles.cancelButton }
                        onClick={ onClose }
                        data-testid="btn-cancel"
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            }
        </Box>
    </Box>;
};

export default BankInfoForm;
