import Redis, { RedisOptions } from 'ioredis';
import asyncLogger, { ILogger } from './logger';
import {
    AUTH_NETWORKS_CACHE_KEY,
    CACHE_HOST,
    CACHE_PORT,
    DEBUG,
    invalidateAuthNetworksCache,
} from './helpers/cluster';
import { environment } from '../config/runtimeConfig';
import { RESOURCE } from './types/CmsEntities';
import { CACHE_KEY_PREFIX } from './constants';

export const REDIS_INIT_ERROR = 'Redis engine is not initialized!';
export const REDIS_BROWSER_ERROR = 'Redis is not available in browser!';

export default class StaticCache {
    private readonly redis?: Redis;
    private readonly redisSubscriber?: Redis;

    private key(keyId: string) {
        return `${ CACHE_KEY_PREFIX }:cache:${ keyId }`;
    }

    constructor(
        private host: string = CACHE_HOST,
        private port: number = CACHE_PORT,
        private options?: RedisOptions,
        private logger: ILogger = asyncLogger,
    ) {
        if (typeof window !== 'undefined') {
            DEBUG && logger.warn(REDIS_BROWSER_ERROR);

            return ;
        }

        if (options) {
            this.redis = new Redis(
                this.port,
                this.host,
                this.options as RedisOptions,
            );
            this.redisSubscriber = new Redis(
                this.port,
                this.host,
                this.options as RedisOptions,
            );
        } else {
            this.redis = new Redis(this.port, this.host);
            this.redisSubscriber = new Redis(this.port, this.host);
        }
    }

    public async get(key: string): Promise<any | null> {
        if (typeof window !== 'undefined') {
            DEBUG && this.logger.warn(REDIS_BROWSER_ERROR);

            return null;
        }

        if (!this.redis) {
            throw new TypeError(REDIS_INIT_ERROR);
        }

        const redis = this.redis as Redis;

        try {
            const data = await redis.get(this.key(key));

            return data ? JSON.parse(data) : null;
        } catch (err) {
            this.logger.error('StaticCache get error:', err);

            return null;
        }
    }

    public async set(key: string, data: any, ttl?: number): Promise<boolean> {
        if (typeof window !== 'undefined') {
            DEBUG && this.logger.warn(REDIS_BROWSER_ERROR);

            return false;
        }

        if (!this.redis) {
            throw new TypeError(REDIS_INIT_ERROR);
        }

        const redis = this.redis as Redis;

        try {
            const setKey = this.key(key);

            await redis.set(setKey, JSON.stringify(data));

            if (ttl) {
                redis.pexpire(setKey, ttl);
            }

            return true;
        } catch (err) {
            this.logger.error('StaticCache set error:', err);

            return false;
        }
    }

    public async del(key: string): Promise<boolean> {
        if (typeof window !== 'undefined') {
            DEBUG && this.logger.warn(REDIS_BROWSER_ERROR);

            return false;
        }

        if (!this.redis) {
            throw new TypeError(REDIS_INIT_ERROR);
        }

        const redis = this.redis as Redis;

        try {
            const keys = await redis.keys(this.key(key));

            if (keys.length) {
                await Promise.all(keys.map((key) => redis.del(key)));

                this.logger.info('Invalidate keys: ', keys);
            }

            return true;
        } catch (error) {
            this.logger.error('StaticCache deleting error:', error);

            return false;
        }
    }

    public async init() {
        const script = `
            local pattern = ARGV[1];
            local cursor = "0";
            local count = 0;
            repeat
                local scanResult = redis.call(
                    "SCAN", cursor,
                    "MATCH", pattern,
                    "COUNT", 1000
                );
                local keys = scanResult[2];
                for i = 1, #keys do
                    local key = keys[i];
                    redis.call("DEL", key);
                    count = count + 1;
                end;
                cursor = scanResult[1];
            until cursor == "0";
            return count;
        `;
        const buildKey = `${ CACHE_KEY_PREFIX }:cache-build-id`;
        const cachedId = await this.redis?.get(buildKey) || '';
        const buildId = process.env.NEXT_BUILD_ID || '';

        if (buildId === 'development' || buildId !== cachedId) {
            await this.redis?.eval(script, 0, `${ CACHE_KEY_PREFIX }:cache:*`);
            await this.redis?.set(buildKey, buildId);
            asyncLogger.log('Old caches invalidated');
        }

        asyncLogger.info('Initialize cache subscriptions');

        await this.redisSubscriber?.subscribe(AUTH_NETWORKS_CACHE_KEY);
        await this.redisSubscriber?.on('message', (channel, msg) => {
            asyncLogger.info(`Auth Networks cache invalidate: ${ msg }`);

            if (channel === AUTH_NETWORKS_CACHE_KEY) {
                invalidateAuthNetworksCache();
            }
        });
    }
}

const scope: any = typeof window === 'undefined' ? global : {};

if (typeof window === 'undefined' && !scope.cache) {
    scope.cache = new StaticCache();
    scope.cache.init().catch(asyncLogger.error);
}

export const cache = scope.cache as StaticCache;

export const generateKey = (
    model: string,
    slug?: string,
) => {
    const slugPart = slug ? `:${ slug }` : '';
    const id = process.env.NEXT_BUILD_ID;

    return `${ environment }:${ id }:${ model }${ slugPart }`;
};

export const needInvalidation = (model: string) => {
    return Object.values(RESOURCE).map(
        v => v.slice(0, -1),
    ).includes(model);
};

export const isCommon = (model: string): boolean => {
    const commonModels = ['menu', 'sidebar', 'footer'];

    return commonModels.includes(model);
};
