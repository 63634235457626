type ImageSize = 'thumbnail' | 'small' | 'medium' | 'large';

const getImageAttributes = (image: any, size?: ImageSize) => {
    if (!image) {
        return {};
    }
    if (!size) {
        return image;
    }

    const defaultAttributes = {
        ...image,
        width: image?.width || 368,
    };
    const formatAttributes = image?.formats[size] || {};
    const newAttributes = { ...image, ...formatAttributes};

    return newAttributes || defaultAttributes;
};

export default getImageAttributes;
