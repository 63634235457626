import React, { FunctionComponent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import parse from 'html-react-parser';
import { isLight } from '../../common/helpers';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Loader } from './PageLoader';
import { KnownBreakpoints, TABLE_BREAKPOINT } from '../../common/constants';
import { LoanDocuments } from '../../config/membersArea';
import NothingFound from '../NothingFound';
import Print from '@mui/icons-material/Print';
import FileDownload from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import useAuthorized from '../../common/hooks/useAuthorized';
import loadAgreement from '../../common/helpers/loanAgreement';
import { useLoan } from '../../common/hooks/AccountInfoProvider';
import InfoModal from '../InfoModal';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import downloadPdf from '../../common/helpers/downloadPdf';
import {
    removeFirstESignaturePart,
    removeSecondESignaturePart,
    removeThirdESignaturePart,
} from '../../common/helpers/loanAgreementSignatureParts';
import { OnSwipe } from '../../common/helpers/onSwipe';
import {
    prettifyLoanAgreement,
} from '../../common/helpers/prettifyLoanAgreement';

const RX_SPACES = /(\s|&nbsp;)+/ig;
const RX_BREAKS = /(<br\s*\/?>\s*){3,}/ig;
const RX_PAGE_BREAKS = /<div[^>]*?class(Name)?="pageBreakDiv"[^>]*?>\s*<\/div>/ig;
const RX_SCRIPT = /<script[^>]*?>.*?<\/script>/ig;
const RX_STYLE = /<style[^>]*?>.*?<\/style>/ig;

function fix(doc: string): string {
    return doc.replace(RX_SPACES, ' ')
    .replace(RX_BREAKS, '<br/><br/>')
    .replace(RX_PAGE_BREAKS, '')
    .replace(RX_SCRIPT, '')
    .replace(RX_STYLE, '');
}

const styles = {
    paper: (theme: Theme, loading: boolean) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main,
        color: (isLight(theme)
            ? theme.palette.common.black
            : theme.palette.common.white) + ' !important',
        ...(loading ? { padding: '25% 0 25% 0' } : {}),
        '& *': {
            fontFamily: 'arial, century, verdana, sans-serif !important',
        },
        '& h3': {
            margin: '10px 0',
        },
        '& p, & li': {
            fontSize: '12pt !important',
        },
        '& a': {
            color: (isLight(theme)
                ? theme.palette.common.black
                : theme.palette.common.white) + '!important',
            textDecoration: 'underline !important',
        },
        '& table, & th, & td': {
            borderCollapse: 'collapse !important',
            border: '1px solid' + (isLight(theme)
                ? theme.palette.common.black
                : theme.palette.common.white) + ' !important',
            '@media print': {
                border: `1px solid ${ theme.palette.common.black } !important`,
            },
        },
        '& td, & th': {
            padding: '3px 6px !important',
            backgroundColor: 'transparent !important',
            fontSize: '12pt !important',
        },
        '& table, & table table': {
            margin: '10px auto !important',
        },
        '& .normalFont div': {
            border: '0 !important',
        },
        '& table table, & table table td, & table table th': {
            border: '0 !important',
        },
        '& ul li': {
            listStyleType: 'none !important',
            textIndent: '-1em',
            paddingBottom: 'unset',
        },
        '& ul li::before': {
            content: '"‣"',
            paddingRight: '10px',
        },
    }),
    actionButtons: {
        position: 'sticky',
        top: 0,
        right: '0',
    },
    closeIcon: (theme: Theme) => ({
        ...theme.typography.h3,
        marginBottom: '10px',
        paddingRight: '10px',
        cursor: 'pointer',
    } as any),
    printButton: (theme: Theme) => ({
        color: isLight(theme)
            ? theme.palette.common.black
            : theme.palette.common.white,
        width: '16px',
        height: '16px',
        position: 'absolute',
        right: '10px',
    }),
    downloadButton: (theme: Theme) => ({
        color: isLight(theme)
            ? theme.palette.common.black
            : theme.palette.common.white,
        width: '16px',
        height: '16px',
        position: 'absolute',
        right: '10px',
        top: '80px',
        padding: '0 !important',
        margin: '0 !important',
        backgroundColor: 'unset',
        '&:hover': { backgroundColor: 'unset' },
    }),
    title: (theme: Theme) => ({
        ...theme.typography.h4,
        marginBottom: '20px',
        paddingTop: '40px',
    }),
    loading: (theme: Theme) => ({
        [theme.breakpoints.up(KnownBreakpoints.tablet)]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
    }),
    textButton: {
        boxShadow: 'unset',
        backgroundColor: 'unset',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: 'unset',
            boxShadow: 'unset',
            transition: 'unset',
            color: 'unset',
        },
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '20px',
    },
};

export interface LoanAgreementProps {
    title?: string;
    loanDocument?: string;
    scrollPoint?: any;
    actions?: boolean;
    onClose?: () => void;
    id?: string;
    loanId?: string;
    customerId?: string;
    onDownloadStart?: () => void;
    onDownloadEnd?: () => void;
}

function updateElements(selector: string, attr: string, value: any) {
    for (const el of document.querySelectorAll(selector)) {
        if (el) {
            if (value === null) {
                el.removeAttribute(attr);
            } else {
                el.setAttribute(attr, value);
            }
        }
    }
}

const viewDocument = (type: LoanDocuments, id: string): void => {
    const docs = document.querySelector(`#${ id }`);

    if (!docs) {
        return;
    }

    docs.querySelectorAll('p, b').forEach((e: any) => {
        if (type === LoanDocuments.GOVERNING_LAW) {
            if (e.textContent?.trim().includes(LoanDocuments.GOVERNING_LAW)) {
                return e.scrollIntoView({ behavior: 'instant' });
            }
        }

        if (type === LoanDocuments.POLICY) {
            if (e.textContent?.trim().includes(LoanDocuments.POLICY)) {
                return e.scrollIntoView({ behavior: 'instant' });
            }
        }

        if (e.textContent?.trim() === type) {
            return e.scrollIntoView({ behavior: 'instant' });
        }
    });
};

const LoanAgreement: FunctionComponent<LoanAgreementProps> = props => {
    const {
        title,
        loanDocument,
        scrollPoint,
        onClose,
        loanId,
        customerId,
        onDownloadStart,
        onDownloadEnd,
    } = props;
    const currentLoan = useLoan();
    const currentLoanId = currentLoan?.id;
    const authorized = useAuthorized();
    const id = props.id || 'loan-agreement';
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const isSmall = useMediaQuery(theme.breakpoints.down(TABLE_BREAKPOINT));
    const [
        agreement,
        setAgreement,
    ] = useState<string | undefined>(loanDocument);
    const [loading, setLoading] = useState(!loanDocument);
    const [error, setError] = useState('');
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        downloading ? onDownloadStart?.() : onDownloadEnd?.();
    }, [downloading, onDownloadStart, onDownloadEnd]);

    useEffect(() => {
        const parent = document.getElementsByTagName('head')[0];
        const style = document.createElement('style');

        style.innerText = `
            @media print {
                * { visibility: hidden; }
                .printable, .printable * {
                    visibility: visible;
                    color: #000000 !important;
                    background-color: #ffffff !important;
                }
                .printable {
                    padding: 0 35px 0 55px;
                }
            }
            .normalFont {
                width: 100% !important;
                padding: ${ isMobile ? '0 35px 0 15px' : '0 40px' } !important;
                font-size: 12pt !important;
            }
            ol.alpha-list {
                margin-left: unset !important;
                margin-top: 10px !important;
            }
            ol li, ul li {
                text-align: left !important;
                margin-top: 10px !important;
            }
            .justify {
                text-align: left !important;
            }
            .weirdList {
                padding-left: 10px !important;
                margin-bottom: 10px !important;
            }
            table.regularRulesTable ul {
                padding-left: 20px !important;
                margin-left: 10px !important;
                margin-top: 10px !important;
            }
            .normalFont div, .normalFont p {
                margin: unset !important;
                margin: unset !important;
                padding: unset !important;
            }
            .normalFont p.centerAlign {
                margin-top: 10px !important;
            }
            div.centerAlign b {
                border-bottom: unset !important;
                text-decoration: underline !important;
                padding: 10px 0 !important;
                display: inline-block;
            }
        `;

        parent.appendChild(style);

        return () => {
            parent.removeChild(style);
        };
    }, [isMobile]);

    useEffect(() => {
        setAgreement(loanDocument);
    }, [loanDocument]);

    useEffect(() => {
        if (agreement ||
            !(agreement || (authorized && (loanId || currentLoanId)))
        ) {
            setLoading(false);
            return ;
        }

        setLoading(true);

        loadAgreement(loanId || currentLoanId).then(
            loanAgreement => {
                setAgreement(loanAgreement);
                setLoading(false);
            },
        );
        // eslint-disable-next-line
    }, [authorized, loanId, currentLoanId, customerId, agreement]);

    useEffect(() => {
        if (scrollPoint && !!agreement) {
            viewDocument(scrollPoint, id);
        }

        setTimeout(() => {
            updateElements('#textOpt', 'disabled', 'disabled');
            updateElements('#mailCheck', 'disabled', 'disabled');
            updateElements('#achCheck', 'disabled', 'disabled');
            updateElements('#debitCheck', 'disabled', 'disabled');
        });

        removeFirstESignaturePart();
        removeSecondESignaturePart();
        removeThirdESignaturePart();
    });

    useEffect(() => {
        if (isSmall && !!agreement) {
            prettifyLoanAgreement();
        }
    }, [isSmall, agreement]);

    const download = async () => {
        setDownloading(true);

        setError(await downloadPdf(
            `/pdf/loan-agreement/${ loanId }`,
            `loan-agreement-${ loanId }.pdf`,
        ));

        setDownloading(false);
    };

    return <Box sx={ styles.paper(theme, loading) } id="loan-agreement-content">
        <InfoModal
            title="File Download Error"
            open={ !!error }
            onClose={ () => setError('') }
        >
            <Typography sx={{
                width:'100%',
                textAlign: 'left',
                paddingLeft: '10px',
            }}>{ error }</Typography>
            <Box sx={ styles.buttonBox }>
                <Button
                    variant="text"
                    color="primary"
                    onClick={ () => setError('') }
                    sx={ styles.textButton }
                >Close</Button>
            </Box>
        </InfoModal>
        { loading
            ? <Box sx={ styles.loading }><Loader /></Box>
            : <OnSwipe onSwipeRight={ onClose }>
                { props.actions && <Box sx={ styles.actionButtons }>
                    <Typography
                        sx={ styles.closeIcon }
                        component="h3"
                        textAlign="end"
                        onClick={ onClose }
                    >&times;</Typography>
                    <IconButton
                        sx={ styles.printButton }
                        onClick={ () => window.print() }
                    ><Print /></IconButton>
                    <IconButton
                        sx={ styles.downloadButton }
                        onClick={ download }
                    >
                        { downloading && <CircularProgress
                            color="info"
                            size={ 26 }
                            thickness={ 3 }
                            sx={{ position: 'absolute' }}
                        /> }
                        <FileDownload />
                    </IconButton>
                </Box> }
                <Box id={ id } className="printable">
                    { title && !loading && agreement && <Typography
                        sx={ styles.title }
                        component="h4"
                        textAlign="center"
                    >
                        { title }
                    </Typography> }
                    { agreement && agreement !== ''
                        ? parse(fix(agreement))
                        : <NothingFound />
                    }
                </Box>
            </OnSwipe>
        }
    </Box>;
};

export default LoanAgreement;
