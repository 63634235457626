import React, { FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '../LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    ApiClient,
    genRecaptchaSessionId,
    isLight,
    isOffline,
} from '../../common/helpers';
import asyncLogger from '../../common/logger';
import isAutoFilled from '../../common/helpers/isAutoFilled';
import OfflineError from '../OfflineError';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../../common/constants';
import PasswordField from '../Form/PasswordField';
import {
    HomePageLink,
    RegisterAccountLink,
    RemindPasswordLink,
} from '../Form/Links';
import EmailField from '../Form/EmailField';
import { NamedRoute } from '../../common/hooks/useNamedRoute';
import { useConfig } from '../../common/hooks/ConfigProvider';
import { login } from '../../common/helpers/login';
import { dontRefreshTokenCookieName } from '../../common/clients/common';
import { invalidateSwCache } from '../../common/helpers/service-worker/swCache';
import {
    LOCALE,
    MFAStatus,
    RECAPTCHA_MA_LOGIN,
    VerificationMethods,
} from '../../config/membersArea';
import { ReCaptcha } from '../ReCaptcha';
import { useSearchParams } from 'next/navigation';
import VerificationForm, { Message } from '../CodeVerificationForm';
import {
    biometricAuthentication,
    BiometricAuthenticationResponse,
    biometricLoginAllowed,
    isBiometricLoginEnabled,
} from '../../common/helpers/biometricLogin';
import { paletteDark } from '../../common/palettes';
import SmartLink from '../SmartLink';

const styles = {
    layout: (theme: Theme) => ({
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            paddingTop: '38px',
        },
    }),
    container: {
        width: '440px',
        paddingRight: '45px',
        paddingLeft: '53px',
    },
    title: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28px',
        marginBottom: '35px',
    }),
    text: (theme: Theme, isForAuthorization: boolean) => ({
        fontSize: '.9em',
        fontWeight: isForAuthorization ? 700 : 'normal',
        marginBottom: '35px',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
        '& a': {
            color: isLight(theme)
                ? theme.palette.text.primary
                : theme.palette.common.white,
            textDecoration: 'underline',
            '&:hover': {
                color: isLight(theme)
                    ? theme.palette.text.primary
                    : theme.palette.common.white,
                textDecoration: 'underline',
            },
        },
    }),
    continueButton: (theme: Theme) => ({
        width: '100%',
        marginBottom: '25px',
        backgroundColor: isLight(theme)
            ? paletteDark.palette.secondary.main
            : paletteDark.palette.primary.dark,
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.secondary.light
                : theme.palette.primary.light
            ,
        },
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            marginBottom: '30px',
        },
    }),
    keepMeSignInLabel: {
        fontSize: '.9em',
        marginLeft: '8px',
    },
    keepMeSignIn: (theme: Theme) => ({
        marginLeft: '8px',
        marginBottom: '52px',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            marginBottom: '40px',
        },
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
    }),
};

export interface LoginFormState {
    password: string;
    email: string;
}

export interface VerificationCode {
    code: string;
    customerId?: string;
    token?: string;
}

export interface LoginFormProps {
    isForIntegration: boolean;
    email?: string;
    password?: string;
    error?: boolean;
    loading?: boolean;
    onLogin?: () => void;
    onChange?: (email: string, password: string) => any;
    onError?: (error: any) => void;
    verificationCodeData?: VerificationCode;
}

export interface VerificationEntity {
    methods: VerificationMethods[];
    value: string;
}

export interface CustomerLogin {
    mfaStatus: MFAStatus;
    token: string | null;
    verificationEntity?: VerificationEntity;
    customerId?: string;
}

const LoginForm: FunctionComponent<LoginFormProps> = props => {
    const { isForIntegration, verificationCodeData } = props;
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );
    const { consumerName, recaptchaId } = useConfig();
    const router = useRouter();
    const [state, setValues] = React.useState<LoginFormState>({
        password: props.email || '',
        email: props.password || '',
    });
    const [validationError, setValidationError] = useState(false);
    const [loginError, setLoginError] = useState(props.error);
    const [networkError, setNetworkError] = useState(false);
    const [loading, setLoading] = useState(!!props.loading);
    const [disabled, setDisabled] = useState(true);
    const [codeDisabled, setCodeDisabled] = useState(true);
    const [keepMeSignIn, setKeepMeSignIn] = useState(true);
    const [sessionId, setSessionId] = useState(genRecaptchaSessionId());
    const [recaptchaToken, setRecaptchaToken] = useState<string>('');
    const [wait, setWait] = useState(false);
    const [timer, setTimer] = useState(30);
    const [message, setMessage] = useState<Message | undefined>(undefined);
    const [verifyCode, setVerifyCode] = useState('');
    const [searchParams] = useSearchParams();
    const [
        loginData,
        setLoginData,
    ] = useState<CustomerLogin | undefined>(undefined);
    const [biometricLoginError, setBiometricLoginError] = useState(false);
    const impersonationToken =
        searchParams?.length === 2 && searchParams[0] === 'impersonate'
            ? searchParams[1]
            : undefined;

    useEffect(() => {
        props.onChange && props.onChange(state.email, state.password);
        // eslint-disable-next-line
    }, [state, props.onChange]);

    useEffect(() => {
        if (recaptchaToken) {
            (window as any).__recaptchaTokenInitialized = true;
        }

        setDisabled(
            loading ||
            validationError ||
            !(state.email && state.password) ||
            !recaptchaToken,
        );
    }, [state, loading, validationError, recaptchaToken]);

    useEffect(() => {
        (async(): Promise<void> => {
            if (isBiometricLoginEnabled()
                && biometricLoginAllowed()
                && !isForIntegration
                && recaptchaToken
            ) {
                try {
                    setLoading(() => true);

                    const userAuthenticated = await biometricAuthentication();

                    if (!userAuthenticated) {
                        setBiometricLoginError(() => true);
                    } else {
                        await handleLogin(
                            state.email,
                            state.password,
                            userAuthenticated,
                        );
                    }
                } catch (err) {
                    asyncLogger.error(err);

                    const isNetError = isOffline(err);
                    setNetworkError(isNetError);
                } finally {
                    setLoading(() => false);
                }
            }
        })();
        // eslint-disable-next-line
    }, [recaptchaToken]);

    useEffect(() => {
        if (impersonationToken) {
            (async () => {
                try {
                    const { data: { impersonate }} =
                    await ApiClient.request('/impersonate', {
                        body: { impersonationToken },
                        withAuth: false,
                    });

                    if (!impersonate) {
                        window.open('/error/401', '_self');
                        return ;
                    }

                    login(impersonate);

                    await invalidateSwCache([NamedRoute.HOME]).catch();
                    await router.replace(NamedRoute.HOME);
                } catch (err) {
                    asyncLogger.error(err);
                    window.open('/error/401', '_self');
                }
            })();

            return;
        }

        // watch for input elements if they were autofilled by browser
        const email = getElement('email');
        const password = getElement('password');
        const interval = setInterval(() => {
            let allFilled = true;

            for (const element of [email, password]) {
                if (!(element && (isAutoFilled(element) || element.value))) {
                    allFilled = false;
                    break;
                }
            }

            if (allFilled) {
                setDisabled(!(window as any).__recaptchaTokenInitialized);

                if ((window as any).__recaptchaTokenInitialized) {
                    clearInterval(interval);
                    delete (window as any).__recaptchaTokenInitialized;
                }
            }
        }, 200);

        setKeepMeSignIn(
            document.cookie.indexOf(`${ dontRefreshTokenCookieName }=`) === -1,
        );

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleGlobalKeyDown);

        return () => {
            window.removeEventListener('keydown', handleGlobalKeyDown);
        };
        // eslint-disable-next-line
    }, [disabled, state]);

    useEffect(() => {
        if (verifyCode !== ''
            && !verifyCode.includes('_')
            && verifyCode.length === 5
        ) {
            setCodeDisabled(false);
        } else {
            setCodeDisabled(true);
        }
    }, [verifyCode]);

    const handleCaptchaVerify = (token: string) => {
        setRecaptchaToken(token);
    };

    const getElement = (id: string): HTMLInputElement | undefined => {
        return document.getElementById(id) as HTMLInputElement | undefined;
    };

    const handleGlobalKeyDown = async (e: KeyboardEvent) => {
        if (e.key === 'Enter' && !disabled) {
            await handleLogin(state.email, state.password);

            props.onLogin && props.onLogin();
        }
    };

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(undefined);
        setVerifyCode(event.target.value);
    };

    const handleSendNewCode = async() => {
        if (wait) {
            return ;
        }

        setMessage(undefined);
        setLoading(true);
        setWait(true);

        try {
            await ApiClient.request('/sendVerificationCode', {
                body: {
                    customerId: loginData?.customerId,
                    entityType: loginData?.verificationEntity?.methods[0],
                },
                withAuth: false,
                headers: { 'x-ws-domain': window?.origin } as any,
            });
        } catch (err) {
            if (isOffline(err)) {
                setLoading(false);
                setNetworkError(true);
                setWait(false);
                return;
            }

            asyncLogger.error(err);

            setWait(false);
            setMessage({
                message: `${ (err as any).message }`,
                severity: 'error',
            });
        }

        setLoading(false);
    };

    const handleVerifyEntity = async() => {
        if (verifyCode !== '' && verifyCode.includes('_')) {
            return ;
        }

        setMessage(undefined);
        setLoading(true);

        await handleLogin(state.email, state.password);
    };

    const handleLogin = async(
        email: string,
        password: string,
        biometrics?: BiometricAuthenticationResponse,
    ): Promise<void> => {
        setLoading(true);

        const verificationCode = verificationCodeData
            || verifyCode && loginData?.customerId
            ? { code: verifyCode, customerId: loginData?.customerId }
            : undefined;

        try {
            const {
                data: { customerLogin },
            } = await ApiClient.request('/login', {
                body: {
                    customerLoginInput: {
                        credentials: {
                            login: email,
                            password,
                        },
                        verificationCode,
                        biometrics,
                    },
                    token: recaptchaToken,
                },
                withAuth: false,
                headers: { 'x-ws-domain': window?.origin } as any,
            });

            if (customerLogin) {
                setLoginError(false);

                if (customerLogin.token) {
                    login(customerLogin.token);

                    if (!isForIntegration) {
                        // await invalidateSwCache([NamedRoute.HOME]).catch();
                        // we need to do replace here, so when the user press
                        // back button it does not show the login page again
                        // from the history, because it is confusing
                        await router.replace(NamedRoute.HOME);
                    }
                } else if (customerLogin.mfaStatus
                    === MFAStatus.CodeRequired
                ) {
                    setLoginData(customerLogin);
                } else if (customerLogin.mfaStatus
                    === MFAStatus.NothingRequired
                    && !customerLogin.token && verifyCode
                ) {
                    setMessage({
                        severity: 'error',
                        message: 'Invalid code!',
                    });
                }
            } else {
                setLoginError(true);
            }
        } catch (e) {
            const isNetError = isOffline(e);

            setLoginError(!isNetError);
            setMessage({ severity: 'error', message: (e as any).message });
            setNetworkError(isNetError);

            props.onError && props.onError(e);
        }

        setRecaptchaToken('');
        delete (window as any).__recaptchaTokenInitialized;
        setSessionId(genRecaptchaSessionId());
        setLoading(false);
    };

    const handleChange = (prop: string) => (
        event: React.ChangeEvent<HTMLElement & { value: any }>,
    ) => {
        setLoginError(false);
        setValues({ ...state, [prop]: event.target.value });
    };

    if (impersonationToken) {
        return <></>;
    }

    if (loginData && loginData.mfaStatus === MFAStatus.CodeRequired) {
        return <Box sx={ styles.layout }>
            <VerificationForm
                entity={
                    loginData.verificationEntity?.methods[0] as string
                }
                value={ loginData.verificationEntity?.value as string }
                wait={ wait }
                timer={ timer }
                loading={ loading }
                disabled={ codeDisabled }
                message={ message }
                handleCodeChange={ handleCodeChange }
                handleSendNewCode={ handleSendNewCode }
                handleVerifyEntity={ handleVerifyEntity }
                numericCode={ false }
                setTimer={ setTimer }
                setWait={ setWait }
                displayTitle
            />
        </Box>;
    }

    return <Box sx={ styles.layout }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box sx={ styles.container }>
            { !isMobile && <Typography sx={ styles.title }>
                { 'Sign In to ' + consumerName }
            </Typography> }
            { isForIntegration
                ? <Typography sx={ styles.text(theme, isForIntegration) }>
                    Username is email address you applied with. If this is
                    your first attempt to log in, the password is the last four
                    digits of your SSN.
                </Typography>
                : !isMobile
                ? <Typography sx={ styles.text(theme, isForIntegration) }>
                        Welcome to member&apos;s area. Please, sign
                        into your existing account or <SmartLink
                        href={ NamedRoute.CREATE_APPLICATION }
                    >register</SmartLink> a new one.
                </Typography> : <></>
            }
            { (loginError || validationError || biometricLoginError) && <Alert
                severity="error"
                iconMapping={{ error: <WarningIcon /> }}
            >{ validationError
                ? 'Provided Email is not a valid e-mail address'
                : networkError
                    ? 'Login failed: connection lost'
                    :  biometricLoginError
                        ? 'Login failed!'
                        : 'Login failed: invalid Email or Password'
            }</Alert> }
            <EmailField
                onChange={ handleChange('email') }
                onError={ () => setValidationError(true) }
                onSuccess={ () => setValidationError(false) }
                extraValidationRule={ /^loan:\d+$/ }
            />
            <PasswordField onChange={ handleChange('password') } />
            <FormControlLabel
                sx={ styles.keepMeSignIn }
                label={
                    <Typography sx={ styles.keepMeSignInLabel }>
                        Keep me signed in
                    </Typography>
                }
                control={
                    <Checkbox
                        checked={ keepMeSignIn }
                        onChange={ e => {
                            let cookie = `${
                                dontRefreshTokenCookieName
                            }=1;path=/;`;
                            if (e.target.checked) {
                                cookie +=
                                    'expires=Thu, 01 Jan 1970 00:00:01 GMT';
                            }
                            document.cookie = cookie;
                            setKeepMeSignIn(e.target.checked);
                        } }
                    />
                }
            />
            <LoadingButton
                loading={ loading }
                variant="contained"
                disabled={ disabled }
                aria-label="sign in"
                onClick={ async () => {
                    try {
                        await handleLogin(state.email, state.password);

                        props.onLogin && props.onLogin();
                    } catch (err) {
                        asyncLogger.error('Login error:', err);
                        props.onError && props.onError(err);
                    }
                } }
                sx={ styles.continueButton }
                data-testid="btn-sign-in"
            >
                Sign In
            </LoadingButton>
            { !isForIntegration && <React.Fragment>
                <Box marginBottom="10px"><RemindPasswordLink /></Box>
                <Box marginBottom="10px"><RegisterAccountLink /></Box>
                <Box><HomePageLink /></Box>
            </React.Fragment> }
        </Box>
        <ReCaptcha
            siteKey={ recaptchaId }
            onVerify={ handleCaptchaVerify }
            language={ LOCALE }
            action={ RECAPTCHA_MA_LOGIN }
            sessionId={ sessionId }
        />
    </Box>;
};

export default LoginForm;
