import React, { useRef } from "react";
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../constants';
import { useTheme } from '@mui/material/styles';

interface OnSwipeProps {
    onSwipeRight?: () => void;
    children: React.ReactNode;
}

const SWIPE_THRESHOLD = 100;
const SWIPE_VELOCITY_THRESHOLD = 0.3;

export const OnSwipe: React.FC<OnSwipeProps> = ({
    onSwipeRight,
    children,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );
    const touchStartX = useRef<number | null>(null);
    const touchStartY = useRef<number | null>(null);
    const touchStartTime = useRef<number | null>(null);

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        touchStartX.current = e.touches[0].clientX;
        touchStartY.current = e.touches[0].clientY;
        touchStartTime.current = Date.now();
    };

    const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        if (touchStartX.current === null
            || touchStartY.current === null
            || !isMobile
        ) {
            return ;
        }

        const touchEndX = e.changedTouches[0].clientX;
        const touchEndY = e.changedTouches[0].clientY;
        const diffX = touchEndX - touchStartX.current;
        const diffY = touchEndY - touchStartY.current;
        const timeElapsed = (Date.now() - (touchStartTime.current ?? 0))
            / 1000;
        const velocityX = Math.abs(diffX / timeElapsed);

        if (Math.abs(diffX) > Math.abs(diffY)
            && Math.abs(diffX) > SWIPE_THRESHOLD
            && velocityX > SWIPE_VELOCITY_THRESHOLD
            && diffX > 0
        ) {
            onSwipeRight?.();
        }

        touchStartX.current = null;
        touchStartY.current = null;
        touchStartTime.current = null;
    };

    return <Box
        onTouchStart={ handleTouchStart }
        onTouchEnd={ handleTouchEnd }
    >
        { children }
    </Box>;
};
